import { Workbox } from "workbox-window";
import alertify from 'alertify.js';

let wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("waiting", () => {
    alertify.alert("A new version is available, update now?", () => {
      wb.messageSW({ type: "SKIP_WAITING" });
    })
  });

  wb.addEventListener("controlling", () => {
    window.location.reload();
  });

  wb.register();
} else {
  wb = null;
}

export default wb;