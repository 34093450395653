<template>
  <nav class="navbar navbar-light bg-light sticky-top">
    <div class="container">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#menu-items"
        aria-controls="menu-items"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/logo.png" alt="free-agents" />
      </router-link>
      <div class="collapse navbar-collapse" id="menu-items">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/become-free-agent">Become a Free Agent</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/find-free-agent">Find a Free Agent</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/search-for-arena">Affiliated Sports Complexes</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  watch: {
    $route() {
      // collapsing menu in mobile mode when navigating
      document.getElementById('menu-items').classList.remove('show');
    }
  }
};
</script>

<style scoped lang="scss">
  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .container {
      justify-content: unset;
    
    .navbar-brand {
      outline: 0;
      margin: 0 auto;

      img {
        width: 180px;
      }
    }

    .nav-link {
      outline: 0;
      color: #000  !important;

      &.active,
      &:hover {
        color: #00adef !important;
      }
      
    }

    }

  }
  .bg-light {
    background-color: #fff !important;
  }
</style>