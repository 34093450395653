<template>
  <footer class="footer mt-auto py-5 bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <h3>About Us</h3>
        <div class="about-website">
          <span>Free Agents is the #1 service for getting you off the bench and into the game!</span>
        </div>
        <div class="city">
          <i class="fa fa-map-marker"></i> City, USA
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 social">
        <h3>Social Media</h3>
       <ul class="list-group list-group-horizontal">
          <li class="list-group-item">
            <a class="social-link" href="javascript:;"><i class="fa fa-facebook-square"></i></a>
          </li>
          <li class="list-group-item">
            <a class="social-link" href="javascript:;"><i class="fa fa-instagram"></i></a>
          </li>
          <li class="list-group-item">
            <a class="social-link" href="javascript:;"><i class="fa fa-twitter-square"></i></a>
          </li>
       </ul>
      </div>
      <div class="col-lg-4 col-sm-12">
        <h3>Contact Us</h3>
        <div class="phone">
          <a href="tel:777-888-9999"><i class="fa fa-phone"></i> 777-888-9999</a>
        </div>
        <div class="email">
          <a href="mailto:email@gmail.com"><i class="fa fa-envelope"></i> email@gmail.com</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="copyright">
          &copy; {{ year }} Website.com
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
  .row {
    color: #000;
  }

  @media (max-width: 767px) {
    .col-sm-12 {
        margin-top: 10px;
        margin-bottom: 20px
    }
  }

  h3 {
    margin-bottom: 15px;
  }
  
  .city,
  .email {
    margin-top: 10px;
  }

  .social {
    text-align: center;

    @media (max-width: 767px) {
      text-align: left;
    }

  }

  .list-group {
    justify-content: center;

    @media (max-width: 767px) {
      justify-content: flex-start;
    }
    
    .list-group-item {
      border: none;
      margin-right: 30px;
      padding: .5rem 0;
  
      .social-link {
        color: #000;
        
        &:hover {
          color: #00adef;
        }
  
        i {
          font-size: 32px;
        }
      }
  
    }
  }
  a {
    color: #000;
    text-decoration: none;
    
    &:hover {
      color: #00adef;
    }
  }

  .copyright {
    text-align: center;
    margin-top: 20px;
  }
</style>