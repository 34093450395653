import { createWebHistory, createRouter } from "vue-router";

const Home = () => import('@/pages/Home.vue');
const BecomeFreeAgent = () => import('@/pages/BecomeFreeAgent.vue');
const FindFreeAgent = () => import('@/pages/FindFreeAgent.vue');
const SearchArena = () => import('@/pages/SearchArena.vue');

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/become-free-agent",
    name: "Become Free Agent",
    component: BecomeFreeAgent
  },
  {
    path: "/find-free-agent",
    name: "Find Free Agent",
    component: FindFreeAgent
  },
  {
    path: "/search-for-arena",
    name: "Search For Arena",
    component: SearchArena
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }  
});

export default router;